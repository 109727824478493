import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/zap-onboard-api/zap-onboard-api/services-unmanaged/zap-onboard-home-page/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Screen15 } from '../../../../components/images/support/xero/Screen15.generated';
import { Screen20 } from '../../../../components/images/support/xero/Screen20.generated';
import { Screen21 } from '../../../../components/images/support/xero/Screen21.generated';
import { Screen22 } from '../../../../components/images/support/xero/Screen22.generated';
import { Screen23 } from '../../../../components/images/support/xero/Screen23.generated';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "syncing-employees-to-xero"
    }}>{`Syncing Employees To Xero`}</h1>
    <p>{`Follow this guide to sync your employees from Canyou to Xero.`}</p>
    <p>{`Before starting ensure that you have connected your Xero organisation (learn how to do that `}<a parentName="p" {...{
        "href": "/support/integrations/xero/setup"
      }}>{`here`}</a>{`).`}</p>
    <hr></hr>
    <ol>
      <li parentName="ol">{`From the Users menu click on the employee to sync to Xero.`}</li>
    </ol>

    <Screen15 mdxType="Screen15" />
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Click the `}<strong parentName="li">{`Sync Xero Employee`}</strong>{` button.`}</li>
    </ol>

    <Screen20 mdxType="Screen20" />
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Review the parts of the profile to Sync to Xero. To sync each part of the profile the employee must have completed the respective workflow task. You can learn more about the different task types `}<a parentName="li" {...{
          "href": "/support/tasks/task-types"
        }}>{`here`}</a></li>
    </ol>

    <Screen21 mdxType="Screen21" />
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Choose whether to create a new employee or update the details of an existing employee.`}</li>
    </ol>

    <Screen22 mdxType="Screen22" />
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Click the `}<strong parentName="li">{`Sync`}</strong>{` button to synchronise the employee to Xero. You should see a message to say `}<strong parentName="li">{`Sync Successful`}</strong>{`. `}</li>
    </ol>

    <Screen23 mdxType="Screen23" />
    <h1 {...{
      "id": "great-job-you-have-now-synchronised-an-employee-to-xero-"
    }}>{`Great job you have now synchronised an employee to Xero 🎉`}</h1>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      